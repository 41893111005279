// NAVIGATION
export const VK_LEFT = 0x25;
export const VK_UP = 0x26;
export const VK_RIGHT = 0x27;
export const VK_DOWN = 0x28;
export const VK_ENTER = 13; // 0x0d
export const VK_BACK = 461;
export const VK_BACK_SPACE = 8; // Used for browser and Panasonic 2019 (Foxxum)

/**
 * See table. Some Samsungs (2017, 2020) trigger both VK_BACK + VK_BACK_TIZEN
 * @link https://developer.samsung.com/smarttv/develop/guides/user-interaction/remote-control.html
 */
export const VK_BACK_TIZEN = 10009;

// COLORS
export const VK_RED = 403;
export const VK_GREEN = 404;
export const VK_YELLOW = 405;
export const VK_BLUE = 406;

// VCR
export const VK_PAUSE = 19;
export const VK_PLAY = 415;
export const VK_STOP = 413;
export const VK_REWIND = 412;
export const VK_FAST_FORWARD = 417;
// export const VK_PREV = 424;
// export const VK_NEXT = 425;
export const VK_PLAY_PAUSE = 463;

// NUMERIC
export const VK_0 = 48;
export const VK_1 = 49;
export const VK_2 = 50;
export const VK_3 = 51;
export const VK_4 = 52;
export const VK_5 = 53;
export const VK_6 = 54;
export const VK_7 = 55;
export const VK_8 = 56;
export const VK_9 = 57;

// CHANNEL SELECTION
export const VK_CHANNEL_UP = 427;
export const VK_CHANNEL_DOWN = 428;

// DESKTOP KEYS
// export const SPACE = 32;
export const VK_DASH = 189;
export const KEYCODE_S = 83;
export const KEYCODE_R = 82;
export const KEYCODE_B = 66;
