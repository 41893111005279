export enum Brand {
  LG = 'LG',
  SAMSUNG = 'Samsung',
  TELIA = 'Telia',
  PANASONIC = 'Panasonic',
  VESTEL = 'Vestel',
  PHILIPS = 'Philips',
  HISENSE = 'Hisense',
  HBBTVEMULATOR = 'HbbTVEmulator',
  UNKNOWN = 'unknown',
}
