/* eslint-disable no-param-reassign */
/* Based on https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart */

export function padStart(originalString: string, targetLength: number, padString: string): string {
  targetLength >>= 0; // truncate if number, or convert non-number to 0;
  padString = String(typeof padString !== 'undefined' ? padString : ' ');
  if (originalString.length >= targetLength) {
    return String(originalString);
  }
  targetLength -= originalString.length;
  if (targetLength > padString.length) {
    padString += padString.repeat(targetLength / padString.length); // append to original to ensure we are longer than needed
  }
  return padString.slice(0, targetLength) + String(originalString);
}
