export const getChromeVersion = (userAgent: string = window.navigator.userAgent): string => {
  const userAgentLowerCased = userAgent.toLowerCase();
  const regex = /chrome\/\s?(\d+)/;
  const found = userAgentLowerCased.match(regex);
  if (found !== null && found.length >= 2) {
    return found[1];
  }

  return 'unknown';
};
