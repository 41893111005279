export enum StorageKey {
  SubtitleSetting = 'svt:hbbtv:subtitleSetting',
  VideoProgress = 'svt:hbbtv:videoprogress',
  SearchHistory = 'svt:hbbtv:searchHistory',
  Favorites = 'svt:hbbtv:favorites',
  ChildProtection = 'svt:hbbtv:childProtection',
  ChildProtectionOverlay = 'svt:hbbtv:childProtectionOverlay',
  DevSettings = 'svt:hbbtv:devSettings',
  EnableDashPlayer = 'svt:hbbtv:enableDashPlayer',
  VideoCompabilityModeEnabled = 'svt:hbbtv:enableSimplerVideoPlayback',
  VideoCapabilities = 'svt:hbbtv:videoCapabilities',
  Profiles = 'svt:hbbtv:profiles',
  ProfileSettings = 'svt:hbbtv:profileSettings',
  CurrentProfileId = 'svt:hbbtv:currentProfileId',
  selectedRegion = 'svt:hbbtv:selectedRegion',
  // TODO: Delete after release of SVTIPLAYTV-1836 has been out in production for awhile
  HasMigratedToProfile = 'svt:hbbtv:hasMigratedToProfile',
  EnableChannels = 'svt:hbbtv:enableChannels',
  EnablePlaybackSpeed = 'svt:hbbtv:enablePlaybackSpeed',
  EnableDrmPlayback = 'svt:hbbtv:enableDrmPlayback',
  CalendarOpenedFlaps = 'svt:hbbtv:calendarOpenedFlaps23',
  Accounts = 'svt:hbbtv:accounts',
  LocalAccountUserId = 'svt:hbbtv:localAccountUserId',
  DeviceId = 'svt:hbbtv:deviceId',
  SeenSurveys = 'svt:hbbtv:seenSurveys',
}
