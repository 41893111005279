import { Brand } from './BrandEnum';
import { getChromeVersion } from './deviceInfoHelpers';
import { IDevice } from './getDeviceInfo';

export function getPhilipsDeviceInfo(
  userAgent: string = window.navigator.userAgent
): IDevice | undefined {
  const userAgentLowerCased = userAgent.toLowerCase();
  if (userAgentLowerCased.indexOf('philips') === -1) {
    return undefined;
  }

  const versionRegex = /(opr\/)(\d{2})/;
  const versionFound = userAgentLowerCased.match(versionRegex);
  const modelRegex = /\(philips, (.*),/;
  const modelFound = userAgentLowerCased.match(modelRegex);

  return {
    brand: Brand.PHILIPS,
    version: (versionFound && `Opera ${versionFound[2]}`) || `unknown`,
    software: 'unknown',
    model: (modelFound && modelFound[1].toUpperCase()) || `unknown`,
    chromeVersion: getChromeVersion(userAgent),
  };

  return undefined;
}
