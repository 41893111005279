import { Environment, getDittoEnvironment, getUnoEnvironment } from '../helpers/getEnvironment';

/* Contento Urls */
export const PRODUCTION_CONTENTO_URL = 'https://contento.svt.se';
export const PRODUCTION_CONTENTO_SEARCH_URL = 'https://contento-search.svt.se';
export const STAGING_CONTENTO_URL = 'https://contento.stage.svt.se';
export const STAGING_CONTENTO_SEARCH_URL = 'https://contento-search.stage.svt.se';
const FEATURE_CONTENTO_URL = 'https://videocollab-contento-feature.dev.borealis.svt.se';

// const PRODUCTION_VIDEO_API = 'https://api.svt.se/video';
// const STAGING_VIDEO_API = 'https://api.stage.svt.se/video';
const STAGING_MANIFEST_PROXY_URL = 'https://api.stage.svt.se/ditto/api/v1/hbbtv';
const PROD_MANIFEST_PROXY_URL = 'https://api.svt.se/ditto/api/v1/hbbtv';
export const IMAGE_API = 'https://www.svtstatic.se/image';
export const GEO_CHECK = 'https://api.svt.se/geocheck';
export const IXPECT = 'https://ixpect.svtplaytv.app.borealis.svt.se';
export const PRODUCTION_CLIENT_LOGGER = 'https://hbbtv.gammalt.svt.se/client-logger/hbbtv-client';
const STAGING_UNO_URL = 'https://auth.stage.uno.svt.se/authentication/v5';
const PRODUCTION_UNO_URL = 'https://auth.prod.uno.svt.se/authentication/v5';
// export const OVERRIDE_LOCAL_CONTETO_URL = 'http://localhost:8888/api/importantMessagesTest';
// export const IXPECT = 'http://localhost:8080';
// export const STAGING_CLIENT_LOGGER = 'https://hbbtv.stage.gammalt.svt.se/client-logger/hbbtv-client';
const STAGING_DITTO_URL = 'https://api.stage.svt.se/ditto/api/v3/manifest';
const PRODUCTION_DITTO_URL = 'https://api.svt.se/ditto/api/v3/manifest';

export const overrideEventApiUrlToIxpect = process.env.IXPECT === 'true';

export const getDirektcenterDuoChatUrl = (
  // streamId = '013f27781044988331802c952de1d4cf' // test id stage
  isProd = true,
  streamId = '3e31b6d47d1e4558f26f7300b4015545' // susannes skafferi advent
): string => {
  const DIREKTCENTER_DUO_CHAT_URL = `https://firestore.googleapis.com/v1beta1/projects/direktcenter-${
    isProd ? 'prod' : 'stage'
  }/databases/(default)/documents/streams/${streamId}:runQuery`;

  return DIREKTCENTER_DUO_CHAT_URL;
};

interface IQueryParams {
  stageApi?: string;
  [key: string]: any;
}

export function getQueryParams(search: string = window.location.search): IQueryParams {
  const params: any = {};
  if (search === '') {
    return {};
  }
  try {
    search
      .split('&')
      .toString()
      .substr(1)
      .split(',')
      .forEach((item) => {
        params[item.split('=')[0]] = decodeURIComponent(item.split('=')[1])
          ? item.split('=')[1]
          : 'No query strings available';
      });
  } catch (error) {
    console.log(`Error getting query params: ${error}`);
  }

  return params;
}

// TODO: One stage env variable to rule them all
export function getContentoUrl(isSearch = false): string {
  const queryParams = getQueryParams();
  const isStage =
    queryParams.stageApi === 'true' ||
    (process.env.CONTENTO_URL && process.env.CONTENTO_URL.indexOf('stage') > -1);

  if (isSearch) {
    if (isStage) {
      return STAGING_CONTENTO_SEARCH_URL;
    }

    return PRODUCTION_CONTENTO_SEARCH_URL;
  }

  if (isStage) {
    return STAGING_CONTENTO_URL;
  }

  if (queryParams.featureApi === 'true') {
    return FEATURE_CONTENTO_URL;
  }

  if (queryParams.contentoTiempoUrl) {
    return decodeURIComponent(queryParams.contentoTiempoUrl);
  }

  return process.env.CONTENTO_URL || PRODUCTION_CONTENTO_URL;
}

export function getUnoUrl(): string {
  const unoEnv = getUnoEnvironment();
  if (unoEnv === Environment.STAGE) {
    return STAGING_UNO_URL;
  }

  return PRODUCTION_UNO_URL;
}

export function getDittoUrl(): string {
  // for local testing  'http://10.240.192.60:9000/api/v1/hbbtv'
  const unoEnv = getDittoEnvironment();
  if (unoEnv === Environment.STAGE) {
    return STAGING_DITTO_URL;
  }

  return PRODUCTION_DITTO_URL;
}

// eslint-disable-next-line import/no-unused-modules
export function getManifestProxyUrl(): string {
  const queryParams = getQueryParams();
  if (queryParams.stageApi === 'true') {
    return STAGING_MANIFEST_PROXY_URL;
  }

  // TODO: prod fallback when done
  return process.env.MANIFEST_PROXY_URL || PROD_MANIFEST_PROXY_URL;
}
