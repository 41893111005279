import { IDevice } from './getDeviceInfo';
import { Brand } from './BrandEnum';
import { getChromeVersion } from './deviceInfoHelpers';
import { getDeviceInfoFromLocalSystem } from './getDeviceInfoFromLocalSystem';

export function getVestelDeviceInfo(
  userAgent: string = window.navigator.userAgent
): IDevice | undefined {
  const userAgentLowerCased = userAgent.toLowerCase();
  if (userAgentLowerCased.indexOf('vestel') === -1) {
    return undefined;
  }

  const regex = /(vestel-mb)(\d{3})/;
  const found = userAgentLowerCased.match(regex);
  if (found !== null && found.length >= 2) {
    const mbVersion = found[2] || '';

    const localSystemDeviceInfo = getDeviceInfoFromLocalSystem();
    const { brand } = localSystemDeviceInfo;

    return {
      brand: brand === 'unknown' || brand === '' ? Brand.VESTEL : brand,
      version: `MB${mbVersion}`,
      software: 'unknown',
      model: `Vestel-MB${mbVersion}`,
      chromeVersion: getChromeVersion(userAgent),
    };
  }

  return undefined;
}

export function isVestelMB130(userAgent: string = window.navigator.userAgent): boolean {
  const userAgentLowerCased = userAgent.toLowerCase();
  if (userAgentLowerCased.indexOf('vestel') === -1) {
    return false;
  }

  const regex = /(vestel-mb)(\d{3})/;
  const found = userAgentLowerCased.match(regex);
  if (found !== null && found.length >= 2) {
    const mbVersion = found[2] || '';
    return mbVersion === '130';
  }
  return false;
}
