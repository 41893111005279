import { IDevice } from './getDeviceInfo';
import { Brand } from './BrandEnum';
import { getChromeVersion } from './deviceInfoHelpers';

export function getSamsungDeviceInfo(
  userAgent: string = window.navigator.userAgent
): IDevice | undefined {
  const userAgentLowerCased = userAgent.toLowerCase();
  const regex = /(samsung;\s*smarttv)(\d{4})/;
  const found = userAgentLowerCased.match(regex);
  if (found !== null && found.length >= 3) {
    return {
      brand: Brand.SAMSUNG,
      version: found[2],
      software: getSamsungSoftwareVersion(userAgent),
      model: getSamsungModel(userAgent),
      chromeVersion: getChromeVersion(userAgent),
    };
  }

  return undefined;
}

const getSamsungModel = (userAgent: string = window.navigator.userAgent) => {
  try {
    return userAgent.split(';')[2].split(':')[1];
  } catch (error) {
    return 'unknown';
  }
};

export const getSamsungSoftwareVersion = (
  userAgent: string = window.navigator.userAgent
): string => {
  try {
    return userAgent.split(';')[3].trim();
  } catch (error) {
    return 'unknown';
  }
};

export const isSamsung = getSamsungDeviceInfo() !== undefined;

export function isSamsungVersionGreaterThan2016(
  userAgent: string = window.navigator.userAgent
): boolean {
  const samsungVersion = getSamsungDeviceInfo(userAgent)?.version;
  if (samsungVersion) {
    try {
      return parseInt(samsungVersion, 10) > 2016;
    } catch (error) {
      return false;
    }
  }
  return false;
}
export function isSamsungVersionGreaterOrEqualToSuppliedVersion(
  suppliedVersion: number,
  userAgent: string = window.navigator.userAgent
): boolean {
  const samsungVersion = getSamsungDeviceInfo(userAgent)?.version;
  if (samsungVersion) {
    try {
      return parseInt(samsungVersion, 10) >= suppliedVersion;
    } catch (error) {
      return false;
    }
  }
  return false;
}

export function isFullHDOnlySamsung(userAgent: string = window.navigator.userAgent) {
  // T-KTSDEUC
  // T-KTSNDEUC
  // T-KTSRDEUC ??????

  const samsungSoftware = getSamsungDeviceInfo(userAgent)?.software;
  if (samsungSoftware) {
    try {
      return (
        samsungSoftware.indexOf('T-KTSDEUC') > -1 || samsungSoftware.indexOf('T-KTSNDEUC') > -1
      );
    } catch (error) {
      return false;
    }
  }
  return false;
}
