function _crypto(): Crypto {
  // @ts-ignore
  return window.crypto || window.msCrypto;
}

function uuidv4Rand(): string {
  const date = new Date();
  let d = date.getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now(); // use high-precision timer if available
  }

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);

    // TODO: Add timestamp to id to make it unique

    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

function uuidv4_crypt(): string {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => {
    return (
      c ^
      (_crypto().getRandomValues(new window.Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16);
  });
}

export const id = (): string => {
  const localCrypto = _crypto();
  if (window.Uint8Array && localCrypto) {
    const randomValues = _crypto().getRandomValues(new window.Uint8Array(1));
    if (typeof randomValues !== 'undefined' && typeof randomValues[0] === 'number') {
      return uuidv4_crypt();
    }
  }

  return `${uuidv4Rand()}.${Date.now()}`;
};
