import { isHbbTv, isHbbTVEmulator } from '../app/helpers/isHbbTv';
import {
  VK_BLUE,
  VK_YELLOW,
  VK_0,
  VK_1,
  VK_2,
  VK_3,
  VK_5,
  VK_7,
  VK_RED,
  VK_GREEN,
  VK_DOWN,
  VK_UP,
  VK_8,
} from '../app/constants/keyCodes';
// import { postConsoleLog } from '../app/helpers/postConsoleLog';

const tvScreenLogElement = document.getElementById('tvScreenLog');

enum ConsoleLevel {
  Trace = 'trace',
  Debug = 'debug',
  Info = 'info',
  Warn = 'warn',
  Error = 'error',
  Log = 'log',
  Assert = 'assert',
}

const levels: ConsoleLevel[] = [
  ConsoleLevel.Trace,
  ConsoleLevel.Debug,
  ConsoleLevel.Info,
  ConsoleLevel.Warn,
  ConsoleLevel.Error,
  ConsoleLevel.Log,
  ConsoleLevel.Assert,
];

const levelColors = {
  [ConsoleLevel.Warn]: 'orange',
  [ConsoleLevel.Error]: 'red',
};

// (blue, red) * 3
const CODE_TOGGLE_DEBUG = [VK_BLUE, VK_RED, VK_BLUE, VK_RED, VK_BLUE, VK_RED].join(',');
// d,d,d
const CODE_TOGGLE_DEBUG_KEYBOARD = [68, 68, 68].join(',');
// blue, 1337, blue
const CODE_ACCESS_STAGE = [VK_BLUE, VK_1, VK_3, VK_3, VK_7, VK_BLUE].join(',');
// Green 1337 Green
const CODE_ACCESS_REF_APP = [VK_GREEN, VK_1, VK_3, VK_3, VK_7, VK_GREEN].join(',');
// Green 1338 Green
const CODE_ACCESS_DR_APP = [VK_GREEN, VK_1, VK_3, VK_3, VK_8, VK_GREEN].join(',');
// yellow, 1337, yellow
const CODE_ACCESS_FEATURE_CONTENTO = [VK_YELLOW, VK_1, VK_3, VK_3, VK_7, VK_YELLOW].join(',');
// blue 100
const CODE_100 = [VK_BLUE, VK_1, VK_0, VK_0, VK_BLUE].join(',');
// blue 150
const CODE_150 = [VK_BLUE, VK_1, VK_5, VK_0, VK_BLUE].join(',');
// blue 200
const CODE_200 = [VK_BLUE, VK_2, VK_0, VK_0, VK_BLUE].join(',');
// (green, blue) * 3
const CODE_DEV = [VK_GREEN, VK_BLUE, VK_GREEN, VK_BLUE, VK_GREEN, VK_BLUE].join(',');

// const playbackRateTest = [VK_BLUE, VK_1, VK_BLUE].join(',');

const CODE_REFRESH = [
  VK_RED,
  VK_RED,
  VK_GREEN,
  VK_GREEN,
  VK_YELLOW,
  VK_YELLOW,
  VK_BLUE,
  VK_BLUE,
].join(',');
const CLEAR_DEBUG = [VK_RED, VK_GREEN, VK_YELLOW, VK_BLUE].join(',');

function wrapConsole(
  consoleSource: { [key: string]: any },
  logLevel: string,
  logLevelWrapper: (...args: any[]) => any
): void {
  if (!(logLevel in consoleSource)) {
    return;
  }

  const original = consoleSource[logLevel] as () => any;
  const wrapped = logLevelWrapper(original);

  // eslint-disable-next-line no-param-reassign
  consoleSource[logLevel] = wrapped;
}

function logToTvScreen(args: any[], level: string): void {
  if (!window.svtBoot.isTvScreenLogVisible || !tvScreenLogElement) {
    return;
  }

  const message = [];
  for (let i = 0; i < args.length; i += 1) {
    const arg = args[i];
    if (typeof arg === 'object') {
      try {
        message.push(JSON.stringify(arg));
      } catch (e) {
        message.push(`Could not stringify${arg}`);
      }
    } else {
      message.push(arg);
    }
  }

  const now = new Date().toISOString().substring(11, 23);
  let logLine = `[${now} ${level}]: ${message.join(' ')}`;
  // postConsoleLog(logLine);
  logLine += '\n';

  const textNode = document.createTextNode(logLine);
  const span = document.createElement('span');
  let color = 'white';

  if (level === ConsoleLevel.Warn || level === ConsoleLevel.Error) {
    color = levelColors[level];
  }

  span.style.color = color;
  span.style.fontSize = '14px';
  span.appendChild(textNode);
  tvScreenLogElement.insertBefore(span, tvScreenLogElement.childNodes[0]);
}

function showOrHideTvScreenLog(): void {
  // @ts-ignore TODO
  tvScreenLogElement.style.display = window.svtBoot.isTvScreenLogVisible ? 'block' : 'none';
}

function refresh(): void {
  window.location.href = `${window.location.origin}${
    window.location.pathname
  }?date=${new Date().getTime()}${window.location.hash}`;
}

function goToStage(): void {
  window.location.href = 'https://stage.hbbtv.play.svt.se/v2/index.xhtml';
}

function goToRefrenceApp(): void {
  window.location.href = 'https://refapp.hbbtv.org/production/catalogue/';
}

function goToDRRefrenceApp(): void {
  window.location.href = 'https://hbbtv-ref.azurewebsites.net/catalogue/';
}

function goToFeatureContentoApi(): void {
  window.location.href = 'https://stage.hbbtv.play.svt.se/v2/index.xhtml?featureApi=true';
}

function goToUrl(lastNumber: number, thirdBlock = 10): void {
  // @ts-ignore: Ignore setting location with string
  window.self.location = `http://192.168.${thirdBlock}.${lastNumber}:3000/index.xhtml`;
}

function goToTvNetworkUrl(lastnumber = 60) {
  // @ts-ignore: Ignore setting location with string
  window.self.location = `http://10.240.192.${lastnumber}:3000/index.xhtml`;
}

export function initTvScreenConsoleLogger(debug: boolean): void {
  const dontUseTvScreenLog = isHbbTVEmulator || !isHbbTv || !('console' in window);
  let logHistory: any = [];
  if (dontUseTvScreenLog) {
    return;
  }

  levels.forEach((level: string) => {
    if (!(level in window.console)) {
      return;
    }

    wrapConsole(window.console, level, function logLevelWrapper(originalConsoleLevel: () => any) {
      return function consoleLevel(...args: any[]): void {
        // Log to console
        if (originalConsoleLevel) {
          Function.prototype.apply.call(originalConsoleLevel, window.console, args);
        }

        // Tv screen log hidden, save to history
        if (!window.svtBoot.isTvScreenLogVisible) {
          logHistory.push(args);
          if (logHistory.length > 20) {
            logHistory.shift();
          }

          return;
        }

        // Log to TV Screen
        if (logHistory.length > 0) {
          logHistory.forEach((item: any) => {
            logToTvScreen(item, level);
          });
          logHistory = [];
        }

        logToTvScreen(args, level);
      };
    });
  });

  window.svtBoot.isTvScreenLogVisible = debug;
  showOrHideTvScreenLog();
}

export function initDevShortKeys(): void {
  let keyPresses: number[] = [];
  let lastKeyPress = 0;
  const timeBetweenPresses = 1000;

  document.addEventListener('keyup', function keyupListener(e: KeyboardEvent) {
    const currentTime = new Date().getTime();
    if (currentTime - lastKeyPress > timeBetweenPresses) {
      keyPresses = [];
    }

    if (tvScreenLogElement) {
      if (e.keyCode === VK_DOWN) {
        tvScreenLogElement.scrollTop += 30;
      } else if (e.keyCode === VK_UP) {
        tvScreenLogElement.scrollTop -= 30;
      }
    }

    lastKeyPress = currentTime;
    keyPresses.push(e.keyCode);

    const currentCode = keyPresses.join(',');

    switch (currentCode) {
      case CODE_TOGGLE_DEBUG:
      case CODE_TOGGLE_DEBUG_KEYBOARD:
        window.svtBoot.isTvScreenLogVisible = !window.svtBoot.isTvScreenLogVisible;
        showOrHideTvScreenLog();
        break;
      case CODE_ACCESS_STAGE:
        goToStage();
        break;
      case CODE_ACCESS_REF_APP:
        goToRefrenceApp();
        break;
      case CODE_ACCESS_DR_APP:
        goToDRRefrenceApp();
        break;
      case CODE_ACCESS_FEATURE_CONTENTO:
        goToFeatureContentoApi();
        break;
      case CODE_100:
        goToUrl(100);
        break;
      case CODE_150:
        goToTvNetworkUrl();
        break;
      case CODE_200:
        goToTvNetworkUrl(200);
        break;
      case CODE_DEV:
        goToUrl(15, 0);
        break;
      case CODE_REFRESH:
        refresh();
        break;
      // case playbackRateTest: {
      //   const vtag = document.querySelector('video');
      //   console.log('playback');
      //   if (vtag?.playbackRate) {
      //     console.log('playbackRate:', vtag?.playbackRate);
      //     console.log(vtag.src);
      //     console.log(vtag.srcObject);
      //     vtag.playbackRate = 2.0;
      //   }
      //   break;
      // }
      case CLEAR_DEBUG:
        if (tvScreenLogElement) {
          tvScreenLogElement.innerHTML = '';
        }
        break;
      default:
    }
  });
}
