import { IDevice } from './getDeviceInfo';
import { Brand } from './BrandEnum';
import { getChromeVersion } from './deviceInfoHelpers';

export function getTeliaDeviceInfo(
  userAgent: string = window.navigator.userAgent
): IDevice | undefined {
  const userAgentLowerCased = userAgent.toLowerCase();
  const regex = /(ekioh\/)([0-9]+\.[0-9]+\.[0-9]+)(\s)/;
  const found = userAgentLowerCased.match(regex);

  if (found !== null && found.length >= 3) {
    // Version is same for all Arris boxes nowadays (from software version 5.15.4 and on)
    return {
      brand: Brand.TELIA,
      version: 'all',
      software: found[2],
      chromeVersion: getChromeVersion(userAgent),
    };
  }

  return undefined;
}

export function checkIfTeliaSoftwareEqualOrGreaterThan5(
  userAgent: string = window.navigator.userAgent
): boolean {
  const teliaVersion = getTeliaDeviceInfo(userAgent)?.software;
  if (teliaVersion) {
    try {
      return parseFloat(teliaVersion) >= 5;
    } catch (error) {
      return false;
    }
  }
  return false;
}
export const isTeliaSoftwareEqualOrGreaterThan5 = checkIfTeliaSoftwareEqualOrGreaterThan5();
