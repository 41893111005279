import { padStart } from './padStart';

export const convertSecondsToTime = (
  timeInSeconds = 0
): { hours: number; minutes: number; seconds: number } => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = Math.floor(timeInSeconds % 60);

  return { hours, minutes, seconds };
};

export const getTwentyFourHourTime = (utcDateTime: string): string => {
  return new Date(utcDateTime).toTimeString().substr(0, 5);
};

export const toReadableTime = (timeInSeconds = 0): string => {
  if (timeInSeconds < 0) {
    return '--:--';
  }
  const time = convertSecondsToTime(timeInSeconds);
  const hours = padStart(String(time.hours), 2, '0');
  const minutes = padStart(String(time.minutes), 2, '0');
  const seconds = padStart(String(time.seconds), 2, '0');

  return `${hours !== '00' ? `${hours}:` : ''}${minutes}:${seconds}`;
};

export const toLocaleTime = (timestamp: string): string => {
  return new Date(timestamp).toLocaleString();
};
