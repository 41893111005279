import { IDevice } from './getDeviceInfo';
import { Brand } from './BrandEnum';
import { getChromeVersion } from './deviceInfoHelpers';

// eslint-disable-next-line import/no-unused-modules
export const isPanasonic = getPanasonicDeviceInfo() !== undefined;

export function isBrokenPanasonic(userAgent: string = window.navigator.userAgent): boolean {
  const userAgentLowerCased = userAgent.toLowerCase();

  const regex = /^(?=.*\bviera\b).*/;
  const found = userAgentLowerCased.match(regex);

  return found !== null && found.length > 0;
}

export function getPanasonicDeviceInfo(
  userAgent: string = window.navigator.userAgent
): IDevice | undefined {
  const userAgentLowerCased = userAgent.toLowerCase();
  const regex = /(viera)\/([0-9.]+)|(\d{4})(iora)/;
  const found = userAgentLowerCased.match(regex);
  if (found !== null && found.length >= 2) {
    const isPanasonicModelViera = found[1] === 'viera';
    const isPanasonicModelIora = found[4] === 'iora';

    const ioraVersion = found[3] || '';
    const vieraSoftware = found[2] || '';

    let model = '';
    if (isPanasonicModelIora) {
      model = 'Iora';
    } else if (isPanasonicModelViera) {
      model = 'Viera';
    }

    return {
      brand: Brand.PANASONIC,
      version: isPanasonicModelIora ? ioraVersion : '',
      software: isPanasonicModelViera ? vieraSoftware : '',
      model,
      chromeVersion: getChromeVersion(userAgent),
    };
  }

  return undefined;
}
