import { IDevice } from './getDeviceInfo';
import { Brand } from './BrandEnum';
import { getLocalSystem, LocalSystemType } from './getLocalSystem';
import { getChromeVersion } from './deviceInfoHelpers';

export function getDeviceInfoFromLocalSystem(): IDevice {
  const localSystem = getLocalSystem();

  let brand: string = Brand.UNKNOWN;
  let version = 'unknown';
  let software = 'unknown';
  let model = 'unknown';
  let chromeVersion = 'unknown';

  if (!localSystem) {
    return {
      brand,
      version,
      software,
      model,
      chromeVersion,
    };
  }

  try {
    brand = tryFetchFromLocalSystem('vendorName', localSystem);
    version = tryFetchFromLocalSystem('hardwareVersion', localSystem);
    software = tryFetchFromLocalSystem('softwareVersion', localSystem);
    model = tryFetchFromLocalSystem('modelName', localSystem);
    chromeVersion = getChromeVersion();
  } catch (e) {
    console.warn(e);
  }

  return {
    brand,
    version,
    software,
    model,
    chromeVersion,
  };
}

function tryFetchFromLocalSystem(
  propertyName: keyof LocalSystemType,
  localSystem: LocalSystemType
) {
  let value = 'unknown';
  try {
    value = localSystem[propertyName];
  } catch (e) {
    console.warn(e);
  }
  return value;
}
