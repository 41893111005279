export type LocalSystemType = Pick<
  OIPF.LocalSystem,
  'vendorName' | 'hardwareVersion' | 'softwareVersion' | 'modelName' | 'familyName'
>;

export const getLocalSystem = (): LocalSystemType | undefined => {
  const configurationEl = document.getElementById('configuration') as OIPF.ConfigurationObject;
  try {
    if (configurationEl && configurationEl.localSystem) {
      const localSystem = configurationEl.localSystem as OIPF.LocalSystem;

      return localSystem;
    }
  } catch (e) {
    console.warn('Crash when trying to parse conf', e);
  }
  return undefined;
};
