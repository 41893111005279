/* eslint-disable prettier/prettier */

let app: any;

export const initAppManager = (): void => {
  const appManagerEl = document.getElementById('app-manager') as any;

  if (appManagerEl && appManagerEl.getOwnerApplication) {
    app = appManagerEl.getOwnerApplication(document);

    if (app) {
      app.show();
      console.log('initAppManager => app.show');

      // @ts-ignore
      if (app.activate) {
        // @ts-ignore
        app.activate();
        console.log('initAppManager => app.activate');
      }

      if (app.activateInput) {
        app.activateInput();
        console.log('initAppManager => app.activateInput');
      }

      const { keyset } = app.privateData;
      keyset.setValue(0x33f); // 0x33F color + nav + vcr + numeric + alpha
    }
  } else {
    console.log('Could not find app-manager');
  }
};

export const destroyAppManager = (): void => {
  console.log('destroyAppManager => Destroying application');
  if (app) {
    try {
      app.destroyApplication();
    } catch (err) {
      console.warn('Failed to destroy application: ', err);
    }
  } else {
    console.warn(
      'Application has not been initialized - call init before tryingt to destroy first'
    );
  }
};
