// Note, sync with trackError.js
import * as Sentry from '@sentry/react';
import { IVideoPlayerContextState } from '../context/VideoPlayerContextTypes';
import LivePlayerError from '../errors/LiveVideoError';
import PlayerError from '../errors/PlayerError';
import { toReadableTime } from './convertTime';
import type { SeverityLevel } from '@sentry/react';

export const SVT_ERROR_ID_KEY = 'errorId';
const ACCOUNT_USER_ID = 'unoAccountId';
const ACCOUNT_401_ERROR = 'uno401Error';
const VIDEO_ID_KEY = 'videoId';
const VIDEO_CDN_ORIGIN_KEY = 'videoCDNOrigin';
const VIDEO_CODEC_KEY = 'videoCodec';
const UHD_MANIFEST_KEY = 'isUHDManifest';
export const SHOW_CRASH_MODAL = 'showCrashModal';
export const DEVICE_INFO = 'deviceInfo';
const AV_CONTROL_OBJECT_ERROR_CODE = 'avControlObjectErrorCode';

const svtErrorIdLength = 5;
const svtErrorIdChars = '123456789abcdefghijkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ';

export function setUnoAccountId(unoAccoutId: string | undefined) {
  Sentry.setTag(ACCOUNT_USER_ID, unoAccoutId);
}

export function createSvtErrorId(): string {
  let result = '';
  for (let i = svtErrorIdLength; i > 0; i -= 1) {
    result += svtErrorIdChars[Math.round(Math.random() * (svtErrorIdChars.length - 1))];
  }

  result = `S-${result}`;
  return result;
}
export const addSentryBreadcrumb = (
  message: string,
  category?: string,
  level: SeverityLevel = 'log'
) => {
  Sentry.addBreadcrumb({
    category,
    message,
    level,
  });
};

function setGlobalNonReactTags(): void {
  Sentry.configureScope((scope) => {
    scope.setTags({
      sessionId: window.svtBoot.sessionId,
    });
  });
}

function setExtraContext(extra: any): void {
  Sentry.configureScope((scope) => {
    scope.setExtra('extra', extra);
  });
}

function removeSessionId(message: string): string {
  const value = message && message.replace(/'data-sdk-session-.*'/, "'data-sdk-session-*'");
  return value;
}

interface IErrorInfo {
  svtErrorId?: string;
  videoId?: string;
  videoCDNOrigin?: string;
  videoCodec?: string;
  isUHDManifest?: boolean;
  extra?: any;
  isShowingOwnErrorModal?: boolean;
  tmaMessage?: string;
  avControlObjectErrorCode?: string | number;
}

export function reportError(
  error: Error,
  errorInfo: IErrorInfo = { isShowingOwnErrorModal: false }
): void {
  const {
    videoId,
    videoCDNOrigin,
    videoCodec,
    isUHDManifest,
    extra,
    svtErrorId = '',
    isShowingOwnErrorModal = false,
    avControlObjectErrorCode,
  } = errorInfo;
  setGlobalNonReactTags();

  let extraObject = extra;
  let message;

  if (typeof error === 'string') {
    message = error;
  } else {
    ({ message } = error);
  }

  if (message && typeof message === 'string') {
    if (typeof extraObject === 'object') {
      extraObject = { ...extraObject, message };
    } else {
      extraObject = { message };
    }
    try {
      // eslint-disable-next-line no-param-reassign
      error.message = removeSessionId(message);
    } catch (ignoredError) {
      console.log(`Can't reassign error message: ${ignoredError}`);
    }
  }

  if (typeof extraObject !== 'undefined') {
    setExtraContext(extraObject);
  }

  console.warn('Sentry error', error, videoId, svtErrorId);

  Sentry.withScope((scope) => {
    scope.setTag(SHOW_CRASH_MODAL, isShowingOwnErrorModal ? 'false' : 'true');
    if (svtErrorId.length) {
      scope.setTag(SVT_ERROR_ID_KEY, svtErrorId);
    }
    if (videoId?.length) {
      scope.setTag(VIDEO_ID_KEY, videoId);
    }
    if (videoCDNOrigin?.length) {
      scope.setTag(VIDEO_CDN_ORIGIN_KEY, videoCDNOrigin);
    }
    if (videoCodec?.length) {
      scope.setTag(VIDEO_CODEC_KEY, videoCodec);
    }
    if (isUHDManifest) {
      scope.setTag(UHD_MANIFEST_KEY, 'true');
    }
    if (extra?.error?.isUno401Error) {
      scope.setTag(ACCOUNT_401_ERROR, 'true');
    }
    if (extra?.sid) {
      scope.setTag('SID', extra.sid);
    }
    if (avControlObjectErrorCode) {
      scope.setTag(AV_CONTROL_OBJECT_ERROR_CODE, avControlObjectErrorCode.toString());
    }
    Sentry.captureException(error);
  });
}

export const reportVideoError = (
  error: LivePlayerError | PlayerError,
  errorInfo: IErrorInfo,
  currentState: IVideoPlayerContextState
): void => {
  const {
    slowUpdates: {
      currentAudioTrack,
      startPosition,
      currentlyPlayingSvtId,
      sid,
      durationMs,
      streamInfo,
      playerState,
      audio,
      isLive,
      video,
      activeViewSection,
      whenToDisplaySkipToNext,
      timeToFirstFrame,
      autoPlayNextItem,
      upNextItem,
      manifestUrl,
      waitingForVideoData,
      isPlayerPaused,
      liveEdge,
      isPlayerAtLiveEdge,
      queueStartId,
      isAbroad,
    },
    playerPosition,
  } = currentState;

  try {
    Sentry.setContext('videoPlayerContext', {
      activeViewSection,
      audio: {
        selectedTrackIndex: audio?.selectedTrack,
        audioTracks: JSON.stringify(audio?.audioTracks),
      },
      video: {
        selectedTrackIndex: video?.selectedTrack,
        videoTracks: JSON.stringify(video?.videoTracks),
      },
      autoPlayNextItem,
      startPosition,
      currentAudioTrack,
      currentlyPlayingSvtId,
      sid,
      duration: durationMs && durationMs / 1000,
      isAbroad,
      isLive,
      isPlayerAtLiveEdge,
      isPlayerPaused,
      liveEdge,
      manifestUrl,
      playerPositionMs: playerPosition,
      playerPosition: toReadableTime(playerPosition / 1000),
      playerState,
      queueStartId,
      streamInfo: {
        programTitle: streamInfo?.programTitle,
        episodeTitle: streamInfo?.episodeTitle,
        duration: streamInfo?.duration,
        liveNow: streamInfo?.liveNow,
        liveStart: streamInfo?.liveStart,
        secondsToLiveRetry: streamInfo?.secondsToLiveRetry,
        geoBlockedSweden: streamInfo?.geoBlockedSweden,
        hasRights: streamInfo?.hasRights,
        isRightsExpired: streamInfo?.isRightsExpired,
      },
      timeToFirstFrame,
      upNextItem,
      waitingForVideoData,
      whenToDisplaySkipToNextInSeconds: whenToDisplaySkipToNext,
      whenToDisplaySkipToNext: toReadableTime(whenToDisplaySkipToNext),
    });
  } catch (err) {
    console.log('could not set custom context in sentry', err);
  }

  reportError(error, {
    ...errorInfo,
    isShowingOwnErrorModal: true,
    extra: { ...errorInfo.extra, sid },
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, default-param-last
export function reportMessage(message: string, svtErrorId = '', extra?: any): void {
  setGlobalNonReactTags();

  if (typeof extra !== 'undefined') {
    setExtraContext(extra);
  }
  const cleanMessage = removeSessionId(message);

  console.info('Sentry message', cleanMessage, extra, svtErrorId);
  Sentry.withScope((scope) => {
    if (svtErrorId.length) {
      scope.setTag(SVT_ERROR_ID_KEY, svtErrorId);
    }
    Sentry.captureMessage(cleanMessage, 'info');
  });
}
