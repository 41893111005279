// Note, sync with reportError.ts

import * as Sentry from '@sentry/react';

import {
  SHOW_CRASH_MODAL,
  SVT_ERROR_ID_KEY,
  createSvtErrorId,
  DEVICE_INFO,
} from '../app/helpers/reportError';
import setupAndGetDeviceInfo from '../app/helpers/getDeviceInfo';
import { getItem } from '../app/helpers/storage';
import { StorageKey } from '../app/context/StorageKeyEnums';
import { getSentryEnvironment } from '../app/helpers/getEnvironment';
import SentryError from '../app/errors/SentryError';

let hasError = false;

function shouldShowCrashModal(event: any): boolean {
  if (!event.exception) {
    // This is not an exception
    return false;
  }

  // Catch all unknown errors. Show the modal if we don't have any tags (indicating that a crash has happened outside of our control)
  if (typeof event.tags === 'undefined') {
    return true;
  }

  return event.tags && event.tags[SHOW_CRASH_MODAL] === 'true';
}

function showErrorModal(svtErrorId: string): void {
  const errorDiv = document.getElementById('errorModal'); // common.html
  if (!errorDiv) {
    console.log('Error div not found!');
    return;
  }

  const errorCodeSpan = errorDiv.getElementsByClassName('errorCode');
  if (!errorCodeSpan || errorCodeSpan.length === 0) {
    console.log('Could not find error-code span');
    return;
  }
  errorCodeSpan[0].textContent = svtErrorId;

  errorDiv.style.display = 'block';
  errorDiv.setAttribute('aria-hidden', 'false');
  hasError = true;
  console.log('ErrorModal: display common.html');
}

export const initSentry = (isDebugEnabled: boolean): void => {
  const listener = (ev: KeyboardEvent): boolean => {
    if (!hasError) {
      return true;
    }

    // Enter or back
    if (ev.keyCode === 13 || ev.keyCode === 461) {
      window.location.href = '#/start/';
      window.location.reload();
    }

    if (ev.preventDefault) {
      ev.preventDefault();
    }

    if (ev.stopPropagation) {
      ev.stopPropagation();
    }

    return false;
  };

  document.addEventListener('keyup', listener, true);

  Sentry.init({
    // dsn: 'https://116c79b769be46bdbe1f3a5dc0d9b574@o1168389.ingest.sentry.io/6269475', not working for webos < 4
    dsn: 'https://116c79b769be46bdbe1f3a5dc0d9b574@sentry.io/6269475',
    // dsn: 'https://35469258748643f6bdb6df36b23180cc@sentry.io/1382035', // TODO: Remove. Old sentry (moved)
    release: window.svtBoot.buildVars.version,
    environment: getSentryEnvironment(),
    debug: isDebugEnabled,
    defaultIntegrations: process.env.NODE_ENV === 'production' ? undefined : false,
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category === 'xhr' && breadcrumb.data?.url.indexOf('ditto') > -1) {
        return null;
      }
      return breadcrumb;
    },
    beforeSend: function dataCallback(event, hint): Sentry.Event {
      const tags = event.tags || {};
      tags.sessionId = window.svtBoot.sessionId;
      tags.deviceId = window.svtBoot.deviceId;
      tags['user.isAbroad'] = window.svtBoot.isAbroad.toString();
      // Sentry.setTag(`${DEVICE_INFO}.brand`, deviceInfo.brand);
      // TODO: Change when we have profiles in localStorage or using Sentry/React error boundry
      const isKids = getItem(StorageKey.CurrentProfileId) === '1';
      tags.isKidsProfile = isKids.toString();

      if (shouldShowCrashModal(event)) {
        const svtErrorId = createSvtErrorId();
        tags[SVT_ERROR_ID_KEY] = svtErrorId;

        tags[SHOW_CRASH_MODAL] = 'true';

        // if (svtBoot.buildVars.env !== 'development') {;

        showErrorModal(svtErrorId);
        // }
      }

      // eslint-disable-next-line no-param-reassign
      event.tags = tags;
      const error = hint?.originalException as SentryError;

      if (error && error.fingerprint !== undefined) {
        // eslint-disable-next-line no-param-reassign
        event.fingerprint = error.fingerprint;
      }

      return event;
    },
  });
  Sentry.setUser({ id: window.svtBoot.deviceId });
  const deviceInfo = setupAndGetDeviceInfo(window.navigator.userAgent);

  if (deviceInfo) {
    Sentry.setTag(`${DEVICE_INFO}.brand`, deviceInfo.brand);
    Sentry.setTag(`${DEVICE_INFO}.version`, deviceInfo.version);
    Sentry.setTag(`${DEVICE_INFO}.software`, deviceInfo.software);
    Sentry.setTag(`${DEVICE_INFO}.chromeVersion`, deviceInfo.chromeVersion);

    if (deviceInfo.model) {
      Sentry.setTag(`${DEVICE_INFO}.model`, deviceInfo.model);
    }
  }
  console.log(`Sentry initialized, debug:${isDebugEnabled}`);
};
